<!-- @format -->
<template>
    <div
        class="gst-ticket-no-seats-available d-flex flex-column align-center justify-center u-height-100">
        <BaseIcon
            class="gst-ticket-no-seats-available__image mb-2"
            :width="width"
            :height="height"
            symbol-id="icons--no_seats_available" />
        <template v-if="$slots.default">
            <slot></slot>
        </template>
        <h5
            v-else
            class="mb-2 text-center">
            {{ $t('_common:messages.noSeats.title') }}
        </h5>
    </div>
</template>

<script>
import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

export default {
    name: 'TicketNoSeatsAvailable',
    components: {
        BaseIcon,
    },
    props: {
        width: {
            type: [String, Number],
            default: null,
        },
        height: {
            type: [String, Number],
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@scssVariables';
@import '@scssMixins';

.gst-ticket-no-seats-available {
    .gst-ticket-no-seats-available__image {
        ::v-deep .gst-svg-icon {
            fill: theme-color('primary');
        }
    }

    h5 {
        line-height: line-height('l');
        font-size: font-size('s');
        font-weight: font-weight('medium');
        letter-spacing: -0.41px;
    }
}
</style>
